import React from "react";
import { useInView } from 'react-intersection-observer';

const SlideInFromLeft = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <div ref={ref} style={{
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateX(0)' : 'translateX(-50px)',
      transition: 'all 0.7s ease-in'
    }}>
      {children}
    </div>
  );
};


const Bgbanner = () => {
  return (
    <section className="pad-tb-150 bg-garantee">
      <div className="container pad-tb-50">
        <div className="about-us-3__content">
          <SlideInFromLeft>
            <h2 className="head-bgbanner">
              สร้างเว็บไซต์ที่ไม่เพียงแค่ดี แต่ยอดเยี่ยม
              พร้อมรับการสนับสนุนจากทีมงานมืออาชีพจาก RAMPAGESOFT วันนี้
              แล้วปลดล็อคศักยภาพธุรกิจของคุณให้โลดแล่นบนเวทีโลก!
            </h2>
          </SlideInFromLeft>
        </div>
      </div>
    </section>
  );
};

export default Bgbanner;

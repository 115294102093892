
import { MDBAccordion, MDBAccordionItem, MDBContainer } from "mdb-react-ui-kit";
import servicesData from "./data/faqData";

const MySwiper = () => {
  return (
    <>
      <section className="pad-tb-100 bg-garantee">
        <div className="container">
          <h2 class="head-fqa">Q&A • คำตอบสำหรับคำถามที่พบบ่อย</h2>

          <MDBContainer
            className="mt-5 fqa-text"
            style={{ maxWidth: "1000px" }}
          >
            <MDBAccordion alwaysOpen initialActive={1}>
              {servicesData.map((service) => (
                <MDBAccordionItem
                  key={service.id} // Ensure each item has a unique key prop for React lists
                  collapseId={service.id} // Ensure dynamic attribute is set correctly
                  headerTitle={service.title} // Properly interpolate JavaScript expressions
                >
                  {service.description}
                </MDBAccordionItem>
              ))}
            </MDBAccordion>
          </MDBContainer>
        </div>
      </section>
    </>
  );
};

export default MySwiper;

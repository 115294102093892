import { useInView } from 'react-intersection-observer';



const FadeInSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.5,  // 50% of the element needs to be visible
  });

  return (
    <div ref={ref} style={{ opacity: inView ? 1 : 0, transition: 'opacity 1.0s ease-in' }}>
      {children}
    </div>
  );
};



const Menu = () => {
  return (
    <section className="about-us-3 overflow-hidden bg-garantee">
      <div className="row flex-xl-row">
        <div className="col-xl-6 df-cen pad-tb-100 pad-lr-30 bg-garantee">
        <FadeInSection>
          <div className="about-us-3__content">
            <h2 className="head1-spa">ในธุรกิจที่เน้นการบริการเช่น</h2>
            <h2 className="head2-spa pad-tb-30">สปา และโรงแรม</h2>

            <div
              className="description mb-40 mb-sm-25 mb-xs-20 wow fadeIn animated"
              data-wow-delay=".5s"
            >
              <p className="text-spa-p textcenter">
                การมีระบบจัดการที่ดีไม่เพียงแต่ช่วยให้การดำเนินงานราบรื่น
                แต่ยังสร้างความประทับใจ และความเชื่อถือให้กับลูกค้า ที่
                RAMPAGESOFT เรามีประสบการณ์ในการพัฒนา
                และติดตั้งระบบจัดการสำหรับธุรกิจสปา และโรงแรม ทั้งในไทย
                และต่างประเทศ โดยเรามีการออกแบบระบบที่เหมาะสมกับความต้องการ
                และสภาพการณ์จริงของแต่ละธุรกิจ
              </p>
            </div>
          </div>
          </FadeInSection>
        </div>
        <div className="col-xl-6 df-cen pad-tb-100 pad-lr-100 bg-bklo">
          <div className="about-us-3__content">
          <FadeInSection>
            <div
              className="description mb-40 mb-sm-25 mb-xs-20 wow fadeIn animated"
            >
              <p className="text-spa-rp textcenter">
                เพิ่มความน่าเชื่อถือ :
                เว็บไซต์ที่ออกแบบมาอย่างดีช่วย
                สร้างภาพลักษณ์ที่ดีและมืออาชีพให้กับธุรกิจ 
                <br />
                <br />
                เข้าถึงลูกค้าใหม่ : ด้วย SEO ที่เหนือชั้น
                ช่วยให้เว็บไซต์ของคุณปรากฏบนหน้าแรกของ GOOGLE
                ทำให้ดึงดูดลูกค้าใหม่ๆ ได้มากขึ้น

                <br />
                <br />
                รองรับการขายออนไลน์ :
                การออกแบบเว็บที่ดีช่วยให้การทำการค้าออนไลน์เป็นไปได้สะดวก
                และมีประสิทธิภาพ ซึ่งเป็นช่องทางสำคัญในการเพิ่มรายได้
                ระบบการจัดการเนื้อหา (CMS) : อำนวยความสะดวกในการอัปเดต
                และจัดการเนื้อหาบนเว็บไซต์ของคุณ
                โดยไม่ต้องมีความรู้ทางเทคนิคลึกซึ้ง 
                <br />
                <br />
                ระบบอีคอมเมิร์ซ : สร้างแพลตฟอร์มขายออนไลน์ที่เสถียร ปลอดภัย
                และเป็นมิตรกับผู้ใช้ เพื่อรองรับการขายสินค้า
                หรือบริการในรูปแบบออนไลน์ 
                <br />
                <br />
                การพัฒนา API และการรวมระบบ:
                ให้บริการการเชื่อมต่อเว็บไซต์ของคุณกับบริการภายนอกอื่นๆ
                อย่างไร้รอยต่อ เช่น ระบบการชำระเงิน หรือโซเชียลมีเดีย
              </p>
            </div>
            </FadeInSection>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;

///var/www/website2023/web-design.rampagesoft.com/my-app/src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/rampagesoft.css";

import Menu from "./menu";
import MetaTAG from "./meta-tag";
import Banner from "./banner";
import Text5icon from "./text5icon";
import Detailphoto from "./detailphoto";
import Bannergarantee from "./bannergarantee";
import Detailphoto2 from "./detailphoto2";
import Spahotel from "./spahotel";
import Bgbanner from "./bg-banner";
import Packagewebsite from "./package-website";
import Slidebar from "./slidebar";
import FAQ from "./faq";
import TTTRAM from "./tttrampage";
import Footer from "./footer";

function App() {
  return (
    <Router>
      <div>
          <MetaTAG />
        <header>
          <Menu />
        </header>
        <main>
          <Routes>
            <Route
              path="/webdesign/*"
              element={
                <>
                  <Banner />
                  <Text5icon />
                  <Detailphoto />
                  <Bannergarantee />
                  <Detailphoto2 />
                  <Spahotel />
                  <Bgbanner />
                  <Slidebar />
                  <Packagewebsite />
                  <FAQ />
                  <TTTRAM />
                </>
              }
            />
            {/* Define other routes here */}
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

// Import Swiper core and required modules
import { Scrollbar, A11y } from 'swiper/modules';
import { useInView } from 'react-intersection-observer';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';


import Logorampage from "./media/logo/LOGO_RAMPAGESOFT_2023.png";
import secslide1 from "./media/slide/digital1.webp";
import secslide2 from "./media/slide/digital2.webp";
import secslide3 from "./media/slide/digital3.webp";
import secslide4 from "./media/slide/digital4.webp";
import secslide5 from "./media/slide/digital5.webp";
import secslide6 from "./media/slide/digital6.webp";

const ZoomInBlur = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div ref={ref} style={{
      opacity: inView ? 1 : 0,
      transform: inView ? 'scale(1)' : 'scale(1.1)',
      filter: inView ? 'blur(0px)' : 'blur(4px)',
      transition: 'all 1.5s ease-out'
    }}>
      {children}
    </div>
  );
};

const Menu = () => {
  return (
    <section
      className="about-us-3 overflow-hidden section-space-top bg-subk">
      <div className="container pad-tb-50">
        <div className="row flex-column-reverse flex-xl-row">
        <div className="col-xl-6 pad-20">
            <div className="about-us-3__media mt-lg-40 mt-md-35 mt-sm-35 mt-xs-35">
              <Swiper
                modules={[Scrollbar, A11y]}  // ใช้เพียง Scrollbar และ A11y
                spaceBetween={50}
                slidesPerView={1}
                scrollbar={{ draggable: true }}  // เอาเฉพาะ scrollbar ที่สามารถลากได้
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
              >
                <SwiperSlide><img src={secslide1} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img src={secslide2} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img src={secslide3} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img src={secslide4} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img src={secslide5} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img src={secslide6} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
              </Swiper>
            </div>
          </div>

          <div className="col-xl-6 df-cen pad-20">
            <div className="about-us-3__content">
            <ZoomInBlur>
              <span
                className="section__subtitle section__subtitle-2 uppercase justify-garantee mb-20 mb-xs-10 wow fadeIn animated"
                data-wow-delay=".1s"
              >
                <img
                  className="xlab-img rightLeft"
                  src={Logorampage}
                  alt="รับทำเว็บไซต์ RAMPAGESOFT"
                />
                Rampagesoft
              </span>

              <h2
                className="head1-detallphoto"
              >
                ปลดล็อคศักยภาพเว็บไซต์ของคุณ<br />
                สู่ความสำเร็จในยุคดิจิทัล
              </h2>
              <h2
                className="head2-detallphoto"

              >
                ทำไมเว็บไซต์ถึงสำคัญกับธุรกิจ?
              </h2>

              <div
                className="description mb-40 mb-sm-25 mb-xs-20 wow fadeIn animated"
                data-wow-delay=".5s"
              >
                <p className='text-detail-p'>
                  ในยุคสมัยที่โลกของเราเปลี่ยนไปอย่างรวดเร็ว ธุรกิจทุกขนาดต้องปรับตัวเพื่อรับมือกับการเปลี่ยนแปลง และการมีเว็บไซต์ที่มีประสิทธิภาพสูง ไม่เพียงแต่เป็นประตูสู่โอกาสใหม่ๆ แต่ยังเป็นเครื่องมือสำคัญในการสร้างความน่าเชื่อถือ และการเติบโตของธุรกิจในวงกว้าง
                </p>
              </div>
              </ZoomInBlur>
              <a href="https://line.me/R/ti/p/%40ave2890k" class="rs-btn button-deatil-p">ติดต่อเรา</a>

            </div>
          </div>
      
        </div>

      </div>
    </section>
  );
};

export default Menu;

import React from 'react';
import { useInView } from 'react-intersection-observer';

import { Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';


import Logorampage from "./media/logo/LOGO_RAMPAGESOFT_2023.png";
import ramslide1 from "./media/slide/ram_slide1.webp";
import ramslide2 from "./media/slide/ram_slide2.webp";
import ramslide3 from "./media/slide/ram_slide3.webp";


const FadeInScaleSection = ({ children }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.3,
  });

  return (
    <div ref={ref} style={{
      opacity: inView ? 1 : 0,
      transform: inView ? 'scale(1)' : 'scale(0.95)',
      transition: 'all 1.5s ease-out'
    }}>
      {children}
    </div>
  );
};


const Menu = () => {
  return (
    <section className="about-us-3 overflow-hidden section-space-top bg-subk">
      <div className="container pad-tb-50">
        <div className="row flex-column-reverse flex-xl-row">
          <div className="col-xl-6 df-cen pad-20">
            <div className="about-us-3__content">
              <FadeInScaleSection>
                <span className="section__subtitle section__subtitle-2 uppercase justify-garantee mb-20 mb-xs-10">
                  <img className="xlab-img rightLeft" src={Logorampage} alt="รับทำเว็บไซต์ RAMPAGESOFT" />
                  Rampagesoft
                </span>
                <h2 className="head1-detallphoto">
                  ออกแบบเว็บไซต์ ตอบโจทย์ทุกธุรกิจ
                </h2>
                <h2 className="head2-detallphoto">
                  เว็บไซต์คือหน้าร้านออนไลน์ของคุณ ดึงดูดลูกค้า เพิ่มยอดขาย ปลดล็อกศักยภาพธุรกิจของคุณ
                </h2>
                <p className='text-detail-p'>
                  รับทำเว็บไซต์ รับออกแบบเว็บไซต์ ให้ตอบโจทย์การทำธุรกิจทุกประเภท
                  และเราช่วยเพิ่มโอกาสธุรกิจของคุณให้เติบโต ไม่ว่าธุรกิจจะมี ขนาดใหญ่ ขนาดSME ขนาดเล็ก จะเป็นรูปแบบบริษัท หน่วยงาน องค์กร หรือบุคคลทั่วไป พร้อม Present ธุรกิจของคุณให้เป็นที่รู้จักในวงกว้าง และทั่วโลก หรือเพื่อประชาสัมพันธ์ โฆษณาธุรกิจ เปิดตัวสินค้าใหม่ เว็บไซต์ จึงเป็นสิ่งสำคัญในการ Present ธุรกิจ เราพร้อมพัฒนาดูแล และดีไซน์ให้กับธุรกิจของคุณด้วยความเป็นมืออาชีพ พร้อมรับประกันคุณภาพงาน ตลอดอายุการใช้งาน
                </p>
              </FadeInScaleSection>

              <a href="https://line.me/R/ti/p/%40ave2890k" className="rs-btn button-deatil-p fade-in" style={{ animationDelay: ".6s" }}>ติดต่อเรา</a>
            </div>
          </div>
          <div className="col-xl-6 pad-20">
            <div className="about-us-3__media mt-lg-40 mt-md-35 mt-sm-35 mt-xs-35">
              <Swiper
                modules={[Scrollbar, A11y]}
                spaceBetween={50}
                slidesPerView={1}
                scrollbar={{ draggable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log('slide change')}
              >
                <SwiperSlide><img className="fade-in" style={{ animationDelay: ".7s" }} src={ramslide1} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img className="fade-in" style={{ animationDelay: ".8s" }} src={ramslide2} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
                <SwiperSlide><img className="fade-in" style={{ animationDelay: ".9s" }} src={ramslide3} alt="รับทำเว็บไซต์ ออกแบบเว็บไซต์" /></SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Menu;
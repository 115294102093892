import { useInView } from 'react-intersection-observer';

import React from 'react';
import copper from './media/copper.webp';
import sliver from './media/sliver.webp';
import gold from './media/gold.webp';
import bgsing from './media/logo/pricing-bg.svg';
import styled from 'styled-components';

const PricingCardPrice = styled.div`
    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: -50px; // แก้เป็น left: -50px;
        width: calc(100% + 67px);
        height: 100%;
        z-index: -1;
        background-image: url(${bgsing}); // เปลี่ยนเป็น hard-coded
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
    }
`

    ;

    const ZoomInBlur = ({ children }) => {
        const { ref, inView } = useInView({
          triggerOnce: true,
          threshold: 0.3,
        });
      
        return (
          <div ref={ref} style={{
            opacity: inView ? 1 : 0,
            transform: inView ? 'scale(1)' : 'scale(1.1)',
            filter: inView ? 'blur(0px)' : 'blur(4px)',
            transition: 'all 1.0s ease-out'
          }}>
            {children}
          </div>
        );
      };

const PackageWebsite = () => {

    return (

        <section className='pad-tb-100 bg-subk'>
            <div className="container">
            <h2 class="head1-detallphoto">ราคา บริการออกแบบเว็บไซต์ รับทำเว็บไซต์</h2>
            <h2 class="head2-detallphoto">RAMPAGESOFT ให้บริการออกแบบ และพัฒนาเว็บไซต์สำหรับธุรกิจหลากหลายประเภท</h2>
            <br/>
                <div className="row mb-minus-30">
                    
                    <div className="col-xl-4 col-md-12 col-lg-4 col-12">
                    <ZoomInBlur>
                        <div className="price-text-red pricing-card bd-3r overflow-hidden mb-30 wow fadeIn animated" data-wow-delay=".5s" data-bg-color="#fff">

                            <img className='img-bottom20' src={copper} alt="รับออกแบบเว็บไซต์ WEB DESIGN 24,990" />
                            <PricingCardPrice backgroundImage={bgsing} className="pricing-card-price mb-30 color-theme-primary price-bg price-text-head">
                                <h2>ราคา<span className='text-price'> 24,990</span> <span>/บาท</span></h2>
                            </PricingCardPrice>
                            <div className="pricing-card-body mb-40">
                                <ul>
                                    <li>รองรับฟังก์ชั่น 28 เมนู</li>
                                    <li>SSD ความจุ 32 Gb.</li>
                                    <li>Website Responsive</li>
                                </ul>
                            </div>
                            <a href="https://line.me/R/ti/p/%40ave2890k" className="rs-btn pricing-card-button">
                                รายละเอียดเพิ่มเติม
                                <span>
                                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.3536 4.40043C10.5488 4.20517 10.5488 3.88858 10.3536 3.69332L7.17157 0.511342C6.97631 0.31608 6.65973 0.31608 6.46447 0.511342C6.2692 0.706604 6.2692 1.02319 6.46447 1.21845L9.29289 4.04688L6.46447 6.8753C6.2692 7.07056 6.2692 7.38715 6.46447 7.58241C6.65973 7.77767 6.97631 7.77767 7.17157 7.58241L10.3536 4.40043ZM-4.35033e-08 4.54688L10 4.54688L10 3.54688L4.35033e-08 3.54688L-4.35033e-08 4.54688Z" fill="white"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        </ZoomInBlur>
                    </div>
                    <div className="col-xl-4 col-md-12 col-lg-4 col-12">
                    <ZoomInBlur>
                        <div className="price-text-blue pricing-card bd-3b overflow-hidden mb-30 wow fadeIn animated" data-wow-delay=".5s" data-bg-color="#fff">

                            <img className='img-bottom20' src={sliver} alt="รับออกแบบเว็บไซต์ WEB APPLICATION 49,990" />
                            <PricingCardPrice backgroundImage={bgsing} className="pricing-card-price mb-30 color-theme-primary price-bg price-text-head">
                                <h2>ราคา<span className='text-price'> 49,990</span> <span>/บาท</span></h2>
                            </PricingCardPrice>
                            <div className="pricing-card-body mb-40">
                                <ul>
                                    <li>รองรับฟังก์ชั่น 33 เมนู</li>
                                    <li>SSD ความจุ 44 Gb.</li>
                                    <li>Website Responsive</li>
                                    <li>Content Writing บริการเขียนบทความ 4 บทความ</li>
                                    <li>Support Analytics & Marketing Tools</li>
                                </ul>
                            </div>
                            <a href="https://line.me/R/ti/p/%40ave2890k" className="rs-btn pricing-card-button">
                                รายละเอียดเพิ่มเติม
                                <span>
                                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.3536 4.40043C10.5488 4.20517 10.5488 3.88858 10.3536 3.69332L7.17157 0.511342C6.97631 0.31608 6.65973 0.31608 6.46447 0.511342C6.2692 0.706604 6.2692 1.02319 6.46447 1.21845L9.29289 4.04688L6.46447 6.8753C6.2692 7.07056 6.2692 7.38715 6.46447 7.58241C6.65973 7.77767 6.97631 7.77767 7.17157 7.58241L10.3536 4.40043ZM-4.35033e-08 4.54688L10 4.54688L10 3.54688L4.35033e-08 3.54688L-4.35033e-08 4.54688Z" fill="white"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        </ZoomInBlur>
                    </div>
                    <div className="col-xl-4 col-md-12 col-lg-4 col-12">
                    <ZoomInBlur>
                        <div className="price-text-gold pricing-card bd-3g overflow-hidden mb-30 wow fadeIn animated" data-wow-delay=".5s" data-bg-color="#fff">

                            <img className='img-bottom20' src={gold} alt="รับออกแบบเว็บไซต์ WEB APPLICATION"/>

                            <PricingCardPrice backgroundImage={bgsing} className="pricing-card-price mb-30 color-theme-primary price-bg price-text-head">
                                <h2>ราคา<span className='text-price'> 69,990</span> <span>/บาท</span></h2>
                            </PricingCardPrice>
                            <div className="pricing-card-body mb-40">
                                <ul>
                                    <li>รองรับฟังก์ชั่น 38 เมนู</li>
                                    <li>SSD ความจุ 64 Gb.</li>
                                    <li>Website Responsive</li>
                                    <li>Content Writing บริการเขียนบทความ 6 บทความ</li>
                                    <li>Support Analytics & Marketing Tools</li>
                                    <li>แสดงจำนวนผู้เข้าชมเนื้อหา และกิจกรรม</li>
                                    <li>กำหนดสิทธิ์สำหรับผู้ใช้ (USER ROLE)</li>
                                    <li>BACKUP DATA</li>
                                </ul>
                            </div>
                            <a href="https://line.me/R/ti/p/%40ave2890k" className="rs-btn pricing-card-button">
                                รายละเอียดเพิ่มเติม
                                <span>
                                    <svg width="11" height="8" viewBox="0 0 11 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.3536 4.40043C10.5488 4.20517 10.5488 3.88858 10.3536 3.69332L7.17157 0.511342C6.97631 0.31608 6.65973 0.31608 6.46447 0.511342C6.2692 0.706604 6.2692 1.02319 6.46447 1.21845L9.29289 4.04688L6.46447 6.8753C6.2692 7.07056 6.2692 7.38715 6.46447 7.58241C6.65973 7.77767 6.97631 7.77767 7.17157 7.58241L10.3536 4.40043ZM-4.35033e-08 4.54688L10 4.54688L10 3.54688L4.35033e-08 3.54688L-4.35033e-08 4.54688Z" fill="white"></path>
                                    </svg>
                                </span>
                            </a>
                        </div>
                        </ZoomInBlur>
                    </div>
                </div>
            </div>
        </section>


    );
};

export default PackageWebsite;
